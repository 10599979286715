<template>
	<div>
		<TransitionRoot
			appear
			:show="open"
			:unmount="false"
			class="px-4 mb-4 mt-6"
			enter="transition-opacity duration-150"
			enter-from="opacity-0"
			enter-to="opacity-100"
			leave="transition-opacity duration-75"
			leave-from="opacity-100"
			leave-to="opacity-0"
			@after-leave="emit('closed')"
		>
			<div class="relative">
				<div class="rounded-lg shadow overflow-hidden focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500 bg-white relative">
					<TextEditor ref="interactionEditor" v-model="interactionContent" :placeholder="'Write your ' + activityStore.interactionLabelSingular.toLowerCase() + ' here...'" @editor="setTextEditorInstance"></TextEditor>
					<!-- Spacer element to match the height of the toolbar -->
					<div aria-hidden="true">
						<div class="h-px" />
						<div class="py-2">
							<div class="py-px">
								<div class="h-9" />
							</div>
						</div>
					</div>
				</div>
				<div class="hidden sm:block absolute top-0 right-0 pt-2 pr-2">
					<Button classes="inline-flex bg-white rounded-md text-gray-400 hover:text-gray-500" @click="emit('closing')">
						<span class="sr-only">Close</span>
						<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
					</Button>
				</div>

				<div class="absolute bottom-16 right-0 z-10">
					<div class="flex flex-shrink flex-nowrap justify-end space-x-2 sm:px-3 cursor-text" @click.self="focusEditorInstance">
						<Listbox v-model="interactionReassign" as="div" class="flex-shrink-0">
							<ListboxLabel class="sr-only"> Reassign</ListboxLabel>
							<div class="relative">
								<ListboxButton class="relative inline-flex items-center rounded-full py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3">
									<FontAwesomeIcon v-if="interactionReassign.value === null" :icon="faUserLargeSlash" aria-hidden="true" class="flex-shrink-0 h-5 w-5 text-gray-300 sm:-ml-1" />
									<img v-else :src="interactionReassign.avatar" alt="" class="flex-shrink-0 h-5 w-5 rounded-full" />
									<span :class="[interactionReassign?.value === null ? '' : 'text-gray-900', 'hidden truncate sm:ml-2 sm:block']">{{ interactionReassign?.value === null ? 'Reassign' : interactionReassign.name }}</span>
								</ListboxButton>

								<Transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
									<ListboxOptions class="absolute right-0 z-10 mt-1 w-52 bg-white shadow max-h-56 rounded-lg text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
										<ListboxOption v-for="assignee in assignees" :key="assignee.value" v-slot="{ active }" as="template" :value="assignee">
											<li :class="[active ? 'bg-gray-100' : 'bg-white', 'cursor-default select-none relative py-2 px-3']">
												<div class="flex items-center">
													<img v-if="assignee.avatar" :src="assignee.avatar" alt="" class="flex-shrink-0 h-5 w-5 rounded-full" />
													<FontAwesomeIcon v-else-if="assignee.value === null" :icon="faUserXmark" aria-hidden="true" class="flex-shrink-0 h-5 w-5 text-gray-400" />
													<FontAwesomeIcon v-else :icon="faUserHeadset" aria-hidden="true" class="flex-shrink-0 h-5 w-5 text-gray-400" />
													<span class="ml-3 block font-medium truncate">
														{{ assignee.name }}
													</span>
												</div>
											</li>
										</ListboxOption>
									</ListboxOptions>
								</Transition>
							</div>
						</Listbox>

						<Listbox v-model="interactionVisibility" as="div" class="flex-shrink-0">
							<ListboxLabel class="sr-only"> Visibility</ListboxLabel>
							<div class="relative">
								<ListboxButton class="relative inline-flex items-center rounded-full py-2 px-2 bg-gray-50 text-sm font-medium whitespace-nowrap hover:bg-gray-100 sm:px-3">
									<FontAwesomeIcon v-if="interactionVisibility.value === ActivityInteractionVisibility.PUBLIC" :icon="faBullhorn" aria-hidden="true" class="flex-shrink-0 h-5 w-5 text-gray-500 sm:-ml-1" />
									<FontAwesomeIcon v-else :icon="faLock" aria-hidden="true" class="flex-shrink-0 h-5 w-5 text-gray-500 sm:-ml-1" />
									<span class="ml-2 text-gray-800">{{
										interactionVisibility.value === ActivityInteractionVisibility.PUBLIC ? 'Public ' + activityStore.interactionLabelSingular.toLowerCase() : 'Private ' + activityStore.interactionLabelSingular.toLowerCase()
									}}</span>
								</ListboxButton>

								<Transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
									<ListboxOptions class="absolute right-0 z-10 mt-1 w-52 bg-white shadow max-h-56 rounded-lg text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
										<ListboxOption v-for="visibilityOption in visibilityOptions" :key="visibilityOption.value" v-slot="{ active }" as="template" :value="visibilityOption">
											<li :class="[active ? 'bg-gray-100' : 'bg-white', 'cursor-default select-none relative py-2 px-3']">
												<div class="flex items-center">
													<span class="block font-medium truncate">
														{{ visibilityOption.name }}
													</span>
												</div>
											</li>
										</ListboxOption>
									</ListboxOptions>
								</Transition>
							</div>
						</Listbox>

						<Listbox v-model="interactionTag" as="div" class="flex-shrink-0">
							<ListboxLabel class="sr-only"> Add a label</ListboxLabel>
							<div class="relative">
								<ListboxButton class="relative inline-flex items-center rounded-full py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3">
									<FontAwesomeIcon :icon="faTag" aria-hidden="true" :class="[interactionTag?.value === null ? 'text-gray-300' : 'text-gray-500', 'flex-shrink-0 h-5 w-5 sm:-ml-1']" />
									<span :class="[interactionTag.value === null ? '' : 'text-gray-900', 'hidden truncate sm:ml-2 sm:block']">{{ interactionTag.value === null ? 'No tag' : interactionTag.name }}</span>
								</ListboxButton>

								<Transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
									<ListboxOptions class="absolute right-0 z-10 mt-1 w-52 bg-white shadow max-h-56 rounded-lg text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
										<ListboxOption v-for="tag in tags" :key="tag.value" v-slot="{ active }" as="template" :value="tag">
											<li :class="[active ? 'bg-gray-100' : 'bg-white', 'cursor-default select-none relative py-2 px-3']">
												<div class="flex items-center">
													<span class="block font-medium truncate">
														{{ tag.name }}
													</span>
												</div>
											</li>
										</ListboxOption>
									</ListboxOptions>
								</Transition>
							</div>
						</Listbox>

						<Listbox v-model="interactionFollowUp" as="div" class="flex-shrink-0">
							<ListboxLabel class="sr-only"> Add a follow up</ListboxLabel>
							<div class="relative">
								<ListboxButton class="relative inline-flex items-center rounded-full py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3">
									<FontAwesomeIcon :icon="faCalendarLinesPen" aria-hidden="true" :class="[interactionFollowUp.value === null ? 'text-gray-300' : 'text-gray-500', 'flex-shrink-0 h-5 w-5 sm:-ml-1']" />
									<span :class="[interactionFollowUp.value === null ? '' : 'text-gray-900', 'hidden truncate sm:ml-2 sm:block']">{{ interactionFollowUp.value === null ? 'Follow up' : interactionFollowUp.name }}</span>
								</ListboxButton>

								<Transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
									<ListboxOptions class="absolute right-0 z-10 mt-1 w-52 bg-white shadow max-h-56 rounded-lg text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
										<ListboxOption v-for="followUpDate in followUpDates" :key="followUpDate.value" v-slot="{ active }" as="template" :value="followUpDate">
											<li :class="[active ? 'bg-gray-100' : 'bg-white', 'cursor-default select-none relative py-2 px-3']">
												<div class="flex items-center">
													<span class="block font-medium truncate">
														{{ followUpDate.name }}
													</span>
												</div>
											</li>
										</ListboxOption>
									</ListboxOptions>
								</Transition>
							</div>
						</Listbox>
					</div>
				</div>
				<div class="absolute bottom-0 inset-x-px">
					<div class="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
						<div class="flex">
							<button type="button" class="-ml-2 -my-2 rounded-full px-3 py-2 inline-flex items-center text-left text-gray-400 group">
								<FontAwesomeIcon :icon="faPaperclip" aria-hidden="true" class="-ml-1 h-5 w-5 mr-2 group-hover:text-gray-500" />
								<span class="text-sm text-gray-500 group-hover:text-gray-600 italic">Attach a file to the {{ activityStore.interactionLabelSingular.toLowerCase() }}</span>
							</button>
						</div>
						<div class="flex-shrink-0 space-x-4">
							<Button :disabled="submitting" label="Cancel" color="white" @click="emit('closing')" />
							<Button :disabled="submitting" :loading="submitting" :label="'Add ' + activityStore.interactionLabelSingular.toLowerCase()" color="primary" @click="prepareTicketInteraction" />
						</div>
					</div>
				</div>
			</div>
		</TransitionRoot>
	</div>
</template>

<script lang="ts" setup>
	import { defineEmits, defineProps, ref, withDefaults } from 'vue';
	import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, TransitionRoot } from '@headlessui/vue';
	import { ActivityInteractionPayload, ActivityInteractionVisibility } from '@/types/activity';
	import { useActivityStore } from '@modules/activities/store';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faBullhorn, faCalendarLinesPen, faLock, faPaperclip, faTag, faUserHeadset, faUserLargeSlash, faXmark } from '@fortawesome/pro-light-svg-icons';
	import { faUserXmark } from '@fortawesome/pro-solid-svg-icons';
	import { useRoute } from 'vue-router';
	import TextEditor from '@components/TextEditor.vue';
	import { Editor } from '@tiptap/vue-3';
	import Button from '@components/Button.vue';

	interface Props {
		open: boolean;
	}

	withDefaults(defineProps<Props>(), {
		open: false,
	});

	const emit = defineEmits<{
		(e: 'closed'): void;
		(e: 'closing'): void;
	}>();

	const interactionEditor = ref<Editor>({} as Editor);
	const activityStore = useActivityStore();
	const route = useRoute();
	const submitting = ref(false);

	const assignees = [
		{ name: 'No reassign', value: null },
		{
			name: 'Wade Cooper',
			value: 1,
			avatar: 'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
		},
		{
			name: 'Olivia Simons',
			value: 2,
			avatar: 'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
		},
		// More items...
	];
	const tags = [
		{ name: 'No tag', value: null },
		{ name: 'Needs more information', value: 'information' },
		{ name: 'Escalate', value: 'escalate' },
		{ name: 'Awaiting works', value: 'waiting' },
		{ name: 'Related issue', value: 'related' },
	];

	const visibilityOptions = [
		{ name: 'Public ' + activityStore.interactionLabelSingular.toLowerCase(), value: ActivityInteractionVisibility.PUBLIC },
		{ name: 'Private ' + activityStore.interactionLabelSingular.toLowerCase(), value: ActivityInteractionVisibility.PRIVATE },
	];
	const followUpDates = [
		{ name: 'No follow up', value: undefined },
		{ name: 'Today', value: 'today' },
		{ name: 'Tomorrow', value: 'tomorrow' },
		{ name: '3 days from now', value: '3 days' },
		{ name: '4 days from now', value: '4 days' },
		{ name: '5 days from now', value: '5 days' },
		{ name: 'End of the week', value: 'end of week' },
		{ name: 'In a week', value: '1 week' },
		{ name: 'Start of next week', value: 'start of next week' },
		{ name: 'End of next week', value: 'end of next week' },
		{ name: 'In a month', value: '1 month' },
		{ name: 'Start of next month', value: 'start of next month' },
		{ name: 'End of next month', value: 'end of next month' },
		// More items...
	];

	const setTextEditorInstance = (editor: Editor) => {
		interactionEditor.value = editor;
	};

	const focusEditorInstance = () => {
		if (interactionEditor.value) {
			interactionEditor.value.commands.focus();
		}
	};

	const interactionContent = ref('');
	const interactionReassign = ref(assignees[0]);
	const interactionTag = ref(tags[0]);
	const interactionVisibility = ref(visibilityOptions[0]);
	const interactionFollowUp = ref(followUpDates[0]);

	const prepareTicketInteraction = async () => {
		submitting.value = true;
		emit('closing');

		const interaction: ActivityInteractionPayload = {
			activity_id: parseInt(route.params.activity_id.toString()),
			content: interactionContent.value ?? '',
			visibility: interactionVisibility.value?.value,
			meta: {
				assign: interactionReassign.value?.value ?? undefined,
				tag: interactionTag.value?.value?.toString(),
				followUp: interactionFollowUp.value?.value,
			},
		};

		await activityStore.addNewActivityInteraction(interaction);
		submitting.value = false;
		interactionContent.value = '';
		interactionReassign.value = assignees[0];
		interactionTag.value = tags[0];
		interactionVisibility.value = visibilityOptions[0];
		interactionFollowUp.value = followUpDates[0];
	};
</script>
