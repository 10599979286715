<template>
	<div>
		<div class="editor-header">
			<div v-if="editor" class="flex flex-row items-center justify-left py-1.5 px-2 border-b-2 space-x-1">
				<TextEditorMenuItem title="bold" :active="editor.isActive('bold')" :disabled="!editor.can().chain().focus().toggleBold().run()" :icon="faBold" @click="editor.chain().focus().toggleBold().run()" />
				<TextEditorMenuItem title="italic" :active="editor.isActive('italic')" :disabled="!editor.can().chain().focus().toggleItalic().run()" :icon="faItalic" @click="editor.chain().focus().toggleItalic().run()" />
				<TextEditorMenuItem title="underline" :active="editor.isActive('underline')" :disabled="!editor.can().chain().focus().toggleUnderline().run()" :icon="faUnderline" @click="editor.chain().focus().toggleUnderline().run()" />
				<TextEditorMenuItem title="strike" :active="editor.isActive('strike')" :disabled="!editor.can().chain().focus().toggleStrike().run()" :icon="faStrikethrough" @click="editor.chain().focus().toggleStrike().run()" />
				<TextEditorMenuItem title="code" :active="editor.isActive('code')" :disabled="!editor.can().chain().focus().toggleCode().run()" :icon="faCode" @click="editor.chain().focus().toggleCode().run()" />
				<TextEditorMenuItem title="highlight" :active="editor.isActive('highlight')" :icon="faHighlighterLine" @click="editor.chain().focus().toggleHighlight().run()" />
				<TextEditorMenuItem title="clear formatting" :icon="faTextSlash" @click="editor.chain().focus().unsetAllMarks().clearNodes().run()" />
				<div class="inline-flex items-center justify-center h-6.5 w-2.5 text-sm font-medium rounded-md focus:outline-none">
					<span class="h-6 border-r-2 border-gray-300"> </span>
				</div>
				<TextEditorMenuItem title="paragraph" :active="editor.isActive('paragraph')" :disabled="!editor.can().chain().focus().toggleCode().run()" :icon="faParagraph" @click="editor.chain().focus().toggleCode().run()" />
				<TextEditorMenuItem title="h1" :active="editor.isActive('heading', { level: 1 })" :icon="faH1" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" />
				<TextEditorMenuItem title="h2" :active="editor.isActive('heading', { level: 2 })" :icon="faH2" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" />
				<TextEditorMenuItem title="h3" :active="editor.isActive('heading', { level: 3 })" :icon="faH3" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" />
				<TextEditorMenuItem title="h4" :active="editor.isActive('heading', { level: 4 })" :icon="faH4" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" />
				<TextEditorMenuItem title="h5" :active="editor.isActive('heading', { level: 5 })" :icon="faH5" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" />
				<TextEditorMenuItem title="h6" :active="editor.isActive('heading', { level: 6 })" :icon="faH6" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" />
				<div class="inline-flex items-center justify-center h-6.5 w-2.5 text-sm font-medium rounded-md focus:outline-none">
					<span class="h-6 border-r-2 border-gray-300"> </span>
				</div>
				<TextEditorMenuItem title="bullet list" :active="editor.isActive('bulletList')" :icon="faListUl" @click="editor.chain().focus().toggleBulletList().run()" />
				<TextEditorMenuItem title="ordered list" :active="editor.isActive('orderedList')" :icon="faListOl" @click="editor.chain().focus().toggleOrderedList().run()" />
				<TextEditorMenuItem title="task list" :active="editor.isActive('taskList')" :icon="faListCheck" @click="editor.chain().focus().toggleTaskList().run()" />
				<TextEditorMenuItem title="code block" :active="editor.isActive('codeBlock')" :icon="faSquareCode" @click="editor.chain().focus().toggleCodeBlock().run()" />
				<TextEditorMenuItem title="blockquote" :active="editor.isActive('blockquote')" :icon="faBlockQuote" @click="editor.chain().focus().toggleBlockquote().run()" />
				<div class="inline-flex items-center justify-center h-6.5 w-2.5 text-sm font-medium rounded-md focus:outline-none">
					<span class="h-6 border-r-2 border-gray-300"> </span>
				</div>
				<TextEditorMenuItem title="link" :active="editor.isActive('link')" :icon="faLinkHorizontal" @click="setLink" />
				<TextEditorMenuItem title="remove link" :disabled="!editor.isActive('link')" :icon="faLinkHorizontalSlash" @click="editor.chain().focus().unsetLink().run()" />
				<TextEditorMenuItem title="horizontal rule" :icon="faHorizontalRule" @click="editor.chain().focus().setHorizontalRule().run()" />
				<TextEditorMenuItem title="hard break" :icon="faTurnDownLeft" @click="editor.chain().focus().setHardBreak().run()" />
				<div class="inline-flex items-center justify-center h-6.5 w-2.5 text-sm font-medium rounded-md focus:outline-none">
					<span class="h-6 border-r-2 border-gray-300"> </span>
				</div>
				<TextEditorMenuItem title="undo" :icon="faArrowRotateLeft" :disabled="!editor.can().chain().focus().undo().run()" @click="editor.chain().focus().toggleBlockquote().run()" />
				<TextEditorMenuItem title="redo" :icon="faArrowRotateRight" :disabled="!editor.can().chain().focus().redo().run()" @click="editor.chain().focus().toggleBlockquote().run()" />
			</div>
		</div>
		<div class="editor-content">
			<EditorContent :editor="editor">
				<button onclick="editor.chain().focus().toggleBold().run()">Bold</button>
			</EditorContent>
		</div>
		<div class="editor-footer"></div>
	</div>
</template>

<script lang="ts" setup>
	import { defineProps, defineEmits, onBeforeUnmount, shallowRef, withDefaults } from 'vue';

	import { Editor, EditorContent } from '@tiptap/vue-3';
	import StarterKit from '@tiptap/starter-kit';
	import Placeholder from '@tiptap/extension-placeholder';
	import {
		faArrowRotateLeft,
		faArrowRotateRight,
		faBlockQuote,
		faBold,
		faCode,
		faH1,
		faH2,
		faH3,
		faH4,
		faH5,
		faH6,
		faHighlighterLine,
		faHorizontalRule,
		faItalic,
		faLinkHorizontal,
		faLinkHorizontalSlash,
		faListCheck,
		faListOl,
		faListUl,
		faParagraph,
		faSquareCode,
		faStrikethrough,
		faTextSlash,
		faTurnDownLeft,
		faUnderline,
	} from '@fortawesome/pro-regular-svg-icons';
	import TextEditorMenuItem from './TextEditorMenuItem.vue';
	import Highlight from '@tiptap/extension-highlight';
	import Link from '@tiptap/extension-link';
	import TaskItem from '@tiptap/extension-task-item';
	import TaskList from '@tiptap/extension-task-list';
	import Underline from '@tiptap/extension-underline';
	import useDialog, { AlertInputTypes } from '@utils/useDialog';

	interface Props {
		placeholder: string;
		modelValue: string;
		focus?: boolean;
	}

	const props = withDefaults(defineProps<Props>(), {
		placeholder: 'What is on your mind?',
	});

	const emit = defineEmits(['update:modelValue', 'editor']);

	const editor = shallowRef<Editor>(
		new Editor({
			extensions: [
				StarterKit,
				Placeholder.configure({
					// Use a placeholder:
					placeholder: props.placeholder,
				}),
				Highlight.configure({ multicolor: true }),
				Link.configure({
					openOnClick: false,
					protocols: ['ftp', 'mailto', 'sftp', 'ssh', 'tel'],
				}),
				TaskList,
				TaskItem.configure({
					nested: true,
				}),
				Underline,
			],
			editorProps: {
				attributes: {
					class: 'min-h-52 px-3 py-3 pb-12 w-full border-0 text-black focus:ring-0 sm:text-sm focus:outline-none',
				},
			},
			content: props.modelValue,
			onUpdate({ editor }) {
				emit('update:modelValue', editor.getHTML());
			},
			onCreate({ editor }) {
				emit('editor', editor);
			},
		})
	);

	onBeforeUnmount(() => {
		editor.value?.destroy();
	});

	const setLink = async () => {
		const previousUrl = editor.value.getAttributes('link').href;
		const result = await useDialog
			.title('Create/edit hyperlink!')
			.input({
				type: AlertInputTypes.Text,
				placeholder: 'Enter the URL',
				value: previousUrl,
				validator: (value) => {
					return new Promise((resolve) => {
						if (value == '') {
							resolve(null);
						}
						let url;
						try {
							url = new URL(value);
							if (url.protocol === 'https:' || url.protocol === 'ftp:' || url.protocol === 'mailto:' || url.protocol === 'sftp:' || url.protocol === 'ssh:' || url.protocol === 'tel:') {
								resolve(null);
							} else {
								resolve('We do not allow links to insecure websites');
							}
						} catch (_) {
							/* empty */
						}
						resolve('Please enter a valid URL');
					});
				},
			})
			.cancelButton({ text: 'Cancel', ariaLabel: 'Cancel', colour: 'red' })
			.prompt('Please enter a valid URL');

		if (result.isConfirmed) {
			// Empty URL or clearing current URL
			if (result.value === '') {
				editor.value.chain().focus().extendMarkRange('link').unsetLink().run();
				return;
			}
			// Update link with URL
			editor.value.chain().focus().extendMarkRange('link').setLink({ href: result.value }).run();
		}
	};
</script>
