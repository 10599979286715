<template>
	<button
		:title="title"
		:disabled="disabled"
		:class="[active ? activeClass : inactiveClass, 'inline-flex items-center justify-center h-' + width + ' w-' + width + ' text-sm font-medium rounded-md focus:outline-none']"
		@click="emit('click')"
	>
		<FontAwesomeIcon v-if="icon" :icon="icon" aria-hidden="true" class="h-4 w-4" />
		<span v-else-if="text">{{ text }}</span>
	</button>
</template>

<script lang="ts" setup>
	import { defineEmits, defineProps, withDefaults } from 'vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

	interface Props {
		active?: boolean;
		disabled?: boolean;
		activeClass?: string;
		inactiveClass?: string;
		title?: string;
		icon?: IconDefinition;
		text?: string;
		width?: string;
	}

	withDefaults(defineProps<Props>(), {
		active: false,
		disabled: false,
		activeClass: 'is-active bg-black text-white hover:bg-white hover:text-black ',
		inactiveClass: 'bg-white text-black hover:bg-black hover:text-white ',
		title: '',
		icon: undefined,
		text: 'btn',
		width: '6.5',
	});

	const emit = defineEmits<{
		(e: 'click'): void;
	}>();
</script>

<style scoped></style>
